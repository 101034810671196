import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
      phoneMask: '+# (###) ###-##-##',
      //phoneIntMask: '###',
      phoneIntMask: ['###', '###-#', '###-##'],
      defaultAvatar: "/img/avatar.png",
      defaultImage: "/img/no-image.png",
      defaultLogo: "/img/no-logo.png",
      userAvatar: "/img/avatar.png",
      userName: null,
      userPosition: null,
      itemsPerPage: 10,
      perPageItems: [5, 10, 25, 50, 100],
      listLanguages: ['ru'],
      showNotifyMessages: "unread",
      lang: "ru",
      timezone: "Asia/Almaty",
      isPbx: false,
      funnelCustomer: 1,
  },
  mutations: {
      updatePhoneMask(state, phoneMask) {
          state.phoneMask = phoneMask
      },
      updatePhoneIntMask(state, phoneIntMask) {
          state.phoneIntMask = phoneIntMask
      },
      updateLanguage(state, language) {
          state.lang = language
      },
      updateListLanguages(state, listLanguages) {
          state.listLanguages = listLanguages
      },
      updateTimezone(state, timezone) {
          state.timezone = timezone
      },
      updateDefaultAvatar(state, defaultAvatar) {
          state.defaultAvatar = defaultAvatar
      },
      updateDefaultImage(state, defaultImage) {
          state.defaultImage = defaultImage
      },
      updateDefaultLogo(state, defaultLogo) {
          state.defaultLogo = defaultLogo
      },
      updateItemsPerPage(state, itemsPerPage) {
          state.itemsPerPage = itemsPerPage
      },
      updatePerPageItems(state, perPageItems) {
          state.perPageItems = perPageItems
      },
      updateIsPbx(state, isPbx) {
          state.isPbx = isPbx
      },
      updateShowNotifyMessages(state, showNotifyMessages) {
          state.showNotifyMessages = showNotifyMessages
      },
      updateUserAvatar(state, avatar) {
          state.userAvatar = avatar
      },
      updateUserName(state, name) {
          state.userName = name
      },
      updateUserPosition(state, position) {
          state.userPosition = position
      }
  },
  actions: {
      setSettings({commit}, user) {
          if (user && user.defaultAvatar) {
              commit('updateDefaultAvatar', user.defaultAvatar)
          }
          if (user && user.defaultImage) {
              commit('updateDefaultImage', user.defaultImage)
          }
          if (user && user.defaultLogo) {
              commit('updateDefaultLogo', user.defaultLogo)
          }
          if (user && user.itemsPerPage) {
              commit('updateItemsPerPage', user.itemsPerPage)
          }
          if (user && user.perPageItems) {
              commit('updatePerPageItems', user.perPageItems)
          }
          if (user && user.language) {
              commit('updateLanguage', user.language)
          }
          if (user && user.listLanguages) {
              commit('updateListLanguages', user.listLanguages)
          }
          if (user && user.timezone) {
              commit('updateTimezone', user.timezone)
          }
          if (user && user.isPbx) {
              commit('updateIsPbx', user.isPbx)
          }
          if (user && user.showNotifyMessages) {
              commit('updateShowNotifyMessages', user.showNotifyMessages)
          }
      },
      setLanguage({commit}, language) {
          commit('updateLanguage', language)
      },
      setUserAvatar({commit}, avatar) {
          commit('updateUserAvatar', avatar)
      },
      setUserName({commit}, name) {
          commit('updateUserName', name)
      },
      setUserPosition({commit}, position) {
          commit('updateUserPosition', position)
      }
  },
    getters: {
        phoneMask: state => state.phoneMask,
        phoneIntMask: state => state.phoneIntMask,
        lang: state => state.lang,
        isPbx: state => state.isPbx,
        listLanguages: state => state.listLanguages,
        showNotifyMessages: state => state.showNotifyMessages,
        timezone: state => state.timezone,
        defaultAvatar: state => state.defaultAvatar,
        defaultImage: state => state.defaultImage,
        defaultLogo: state => state.defaultLogo,
        itemsPerPage: state => state.itemsPerPage,
        perPageItems: state => state.perPageItems,
        funnelCustomer: state => state.funnelCustomer,
        userAvatar: state => state.userAvatar,
        userName: state => state.userName,
        userPosition: state => state.userPosition
    },
  modules: {}
});
