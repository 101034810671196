<template>
    <v-navigation-drawer v-model="drawerShow" :clipped="$vuetify.breakpoint.smAndDown"
                         :mini-variant="mini" :mini-variant.sync="isMini"
                         :expand-on-hover="mini" mobile-breakpoint="960"
                         :width="$vuetify.breakpoint.smAndDown ? '280' : '230'"
                         mini-variant-width="80" height="100%" light app>
        <v-list class="py-0 comany-logo" color="primary" dark
                style="border-radius: 0; height: 64px">
            <v-list-item>
                <v-list-item-content v-if="($vuetify.breakpoint.smAndDown && mini) || isMini">
                    <v-img src="/img/logo-small.png" max-height="40px" contain></v-img>
                </v-list-item-content>
                <v-list-item-content v-else>
                    <v-img src="/img/logo.png" max-height="40px" contain></v-img>
                </v-list-item-content>
                <v-list-item-icon v-if="$vuetify.breakpoint.smAndDown">
                    <v-btn small icon @click.stop="navToggle" class="mx-0">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-divider class="my-0"></v-divider>
        <scrollbar ref="scrollbar" :swicher="!$vuetify.breakpoint.smAndDown"
                   :settings="settingsScrollbar" class="inner-scrollbar">
            <v-list class="py-0" dense v-for="(group, index) in items" :key="index">
                <template v-for="item in group">
                    <v-subheader v-if="item.header" v-show="item.visible">
                        {{ item.title }}
                    </v-subheader>
                    <v-list-group v-else-if="!!item.items" v-show="item.visible"
                                  :prepend-icon="item.icon" no-action
                                  :key="item.title" v-model="item.active">
                        <template v-slot:activator>
                            <v-list-item-content class="nav-bar-content">
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item v-for="subItem in item.items" :key="subItem.title"
                                     v-show="subItem.visible"
                                     @click.native="subItem.action ? subItem.action() : false"
                                     :to="subItem.to" ripple
                                     :exact="subItem.exact !== undefined? subItem.exact : true">
                            <v-list-item-icon>
                                <v-icon>{{ subItem.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pl-2 nav-bar-content">
                                <v-list-item-title>{{subItem.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item v-else v-show="item.visible"
                        @click.native="item.action ? item.action() : false"
                        href="javascript:void(0)" :to="item.to" ripple
                        :exact="item.exact !== undefined ? item.exact : true"
                        :key="item.title">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="nav-bar-content">
                            <v-list-item-title>{{item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </scrollbar>
    </v-navigation-drawer>
</template>
<script>
import Scrollbar from "./Scrollbar"

export default {
    name: "AppSidebar",
    components: {
        Scrollbar,
    },
    inject: ["forceRerender"],
    props: {
        mini: Boolean,
        drawer: Boolean,
    },
    data() {
        return {
            isMini: null,
            items: [],
            settingsScrollbar: {
                suppressScrollX: true,
            },
        }
    },
    computed: {
        drawerShow: {
            get: function () {
                return this.drawer
            },
            set: function (state) {
                if (state !== this.drawer) {
                    this.navToggle()
                }
            },
        },
        expandOnHover: function () {
            return this.mini
        },
        routePath() {
            return this.$route.path
        },
    },
    watch: {
        routePath(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.navigation()
            }
        },
    },
    mounted() {
        this.navigation()
    },
    methods: {
        navToggle() {
            this.$emit("nav-toggle")
        },
        navMiniToggle() {
            this.$emit("nav-mini-toggle")
        },
        navigation() {
            this.items = [
                [
                    {
                        title: this.$t("menu_general"),
                        header: true,
                        visible: this.$auth.check(),
                    },
                    {
                        title: this.$t("menu_users"),
                        icon: "mdi-account-group mdi-18px",
                        visible: this.can(["administrator", "manager"]),
                        exact: false,
                        active: ["/administrator", "/manager"].arrayStartsWith(
                            this.routePath
                        ),
                        items: [
                            {
                                title: this.$t("menu_administrators"),
                                icon: "mdi-account-tie mdi-18px",
                                to: {name: "administrator"},
                                action: this.forceRerender,
                                visible: this.can(["administrator"]),
                                exact: false,
                            },
                            {
                                title: this.$t("menu_clients"),
                                icon: "mdi-account-supervisor mdi-18px",
                                to: {name: "client"},
                                action: this.forceRerender,
                                visible: this.can(["administrator", "manager"]),
                                exact: false,
                            },
                        ],
                    },

                    {
                        title: this.$t("menu_companies"),
                        icon: "mdi-home-city mdi-18px",
                        to: {name: "company"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_applications"),
                        icon: "mdi-transit-connection-variant mdi-18px",
                        to: {name: "application"},
                        action: this.forceRerender,
                        visible: this.can(["administrator", "manager"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_tracking_container"),
                        icon: "mdi-train-variant mdi-18px",
                        to: {name: "tracking_container"},
                        action: this.forceRerender,
                        visible: this.can(["administrator", "manager"]),
                        exact: false,
                    },

                    {
                        title: this.$t("menu_handbooks"),
                        header: true,
                        visible: this.can(["administrator"]),
                    },
                    {
                        title: this.$t("menu_tracking"),
                        icon: "mdi-arrow-decision mdi-18px",
                        to: {name: "tracking"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_countries"),
                        icon: "mdi-web",
                        to: {name: "country"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_cities"),
                        icon: "mdi-city-variant",
                        to: {name: "city"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_wagons"),
                        icon: "mdi-view-stream",
                        to: {name: "wagon"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_containers"),
                        icon: "mdi-cube-outline",
                        to: {name: "container"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_stations"),
                        icon: "mdi-language-ruby-on-rails",
                        to: {name: "station"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_statuses"),
                        icon: "mdi-transfer",
                        to: {name: "status"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_container_types"),
                        icon: "mdi-format-list-bulleted-type",
                        to: {name: "container_type"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_conditions"),
                        icon: "mdi-caravan",
                        to: {name: "condition"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_integration"),
                        header: true,
                        visible: this.can(["administrator", "manager"]),
                    },
                    {
                        title: this.$t("menu_update_application"),
                        icon: "mdi-download-box-outline",
                        to: {name: "update_application"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_update_application_client"),
                        icon: "mdi-download-box-outline",
                        to: {name: "update_application_client"},
                        action: this.forceRerender,
                        visible: this.can(["administrator", "manager"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_new_data"),
                        icon: "mdi-database",
                        to: {name: "integration_data"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_integration_errors"),
                        icon: "mdi-valve-closed",
                        to: {name: "integration_errors"},
                        action: this.forceRerender,
                        visible: this.can(["administrator"]),
                        exact: false,
                    },
                ],
            ]
        },
    },
}
</script>
