<template>
    <v-app-bar :clipped-left="$vuetify.breakpoint.smAndDown" :fixed="$vuetify.breakpoint.smAndUp"
               color="primary" app dark>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click.stop="navToggle"></v-app-bar-nav-icon>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndUp" @click.stop="navMiniToggle">
            <v-icon v-if="!mini">mdi-menu-open</v-icon>
            <v-icon v-if="mini" class="mdi-flip-h">mdi-menu-open</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndUp" @click.stop="toggleFullscreen">
            <v-icon v-if="!isFullscreen">mdi-fullscreen</v-icon>
            <v-icon v-if="isFullscreen">mdi-fullscreen-exit</v-icon>
        </v-app-bar-nav-icon>
        <v-spacer/>
        <v-menu v-model="menu" nudge-bottom="30" min-width="200" transition="slide-y-transition" offset-y light>
            <template v-slot:activator="{ on }">
                <v-icon slot="activator" v-on="on"></v-icon>
            </template>
            <v-card>
                <v-list color="grey lighten-4" light dense>
                    <v-list-item v-for="item in items" :key="item.title" v-show="item.visible"
                                 @click.native="item.action ? item.action() : false" href="javascript:void(0)"
                                 :to="item.to" ripple :exact="item.exact !== undefined ? item.exact : true">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
        <v-avatar color="white" @click.stop="menu = !menu" size="44">
            <v-img :src="userAvatar" />
        </v-avatar>
        <v-icon @click.stop="menu = !menu">{{ menu ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
        <v-card v-if="$vuetify.breakpoint.smAndUp" class="mx-auto" color="transparent" max-width="150" flat outlined>
            <v-list-item dense>
                <v-list-item-content>
                    <v-list-item-title>{{ userName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ userPosition }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-card>
        <v-menu v-model="menuBadge" nudge-bottom="6" min-width="280" max-width="280"
                transition="slide-y-transition" bottom offset-y :close-on-click="closeOnClickMenu">
            <template v-slot:activator="{ on }">
                <v-btn icon v-ripple slot="activator" v-on="on">
                    <v-badge color="red white--text" :content="notifyUnreadCount"
                             :value="notifyUnreadCount" overlap>
                        <v-icon color="tertiary">mdi-bell</v-icon>
                    </v-badge>
                </v-btn>
            </template>
            <v-card v-if="menuBadge" class="overflow-hidden" width="300" max-height="500" elevation="8">
                <v-card-title class="white--text primary py-1">
                    <v-flex class="flex-column justify-space-between">
                        <div class="float-left justify-start">
                            <v-tooltip v-if="notifyType === 'read'" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="notifyType === 'read'" v-on="on" class="white--text"
                                           text @click.stop="notifyShow('unread')">
                                        {{ $t('show_unread') }} ({{ notifyUnreadCount }})
                                    </v-btn>
                                </template>
                                <span>{{ $t('show_unread_messages') }}</span>
                            </v-tooltip>
                            <v-tooltip v-if="notifyType === 'unread'" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="notifyType === 'unread'" v-on="on" class="white--text"
                                           text @click.stop="notifyShow('read')">
                                        {{ $t('show_read') }} ({{ notifyReadCount }})
                                    </v-btn>
                                </template>
                                <span>{{ $t('show_read_messages') }}</span>
                            </v-tooltip>
                        </div>
                        <div class="float-right justify-end h-30px">
                            <v-tooltip v-if="notifyType === 'read' && notifyItems.length > 0" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="notifyType === 'read'" v-on="on" class="white--text"
                                           fab small icon @click.stop="notifyMark('unreadAll')">
                                        <v-icon>mdi-checkbox-multiple-blank-circle-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('mark_all_as_unread') }}</span>
                            </v-tooltip>
                            <v-tooltip v-if="notifyType === 'unread' && notifyItems.length > 0" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="notifyType === 'unread'" v-on="on" class="white--text"
                                           fab small icon @click.stop="notifyMark('readAll')">
                                        <v-icon>mdi-checkbox-multiple-marked-circle-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('mark_all_as_read') }}</span>
                            </v-tooltip>
                        </div>
                    </v-flex>
                </v-card-title>
                <v-card-text v-if="notifyItems.length === 0" class="py-3">
                    <div class="text-center justify-content-center subtitle-1 font-weight-medium">
                        {{ $t('no_notifications') }}
                    </div>
                </v-card-text>
                <v-virtual-scroll v-if="notifyItems && notifyItems.length > 0"
                                  :items="notifyItems" :item-height="64" class="virtual-scroll-divider"
                                  :height="(notifyItems.length <= 5) ? (notifyItems.length * 64) : '250'">
                    <template v-slot:default="{ item, index }">
                        <v-list three-line>
                            <v-list-item v-if="item" class="py-0" style="min-height: auto">
                                <v-list-item-content class="py-0" @click="showNotify(item)">
                                    <v-list-item-title class="py-0 text-sm-body-2">
                                        {{ item.title }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="py-0 text-caption">
                                        {{ item.message }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action class="py-0 my-0">
                                    <v-tooltip v-if="item.read_at === null" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-if="item.read_at === null" v-on="on"
                                                   color="white" class="text--primary"
                                                   fab small icon @click.stop="notifyMark('read', item)">
                                                <v-icon>mdi-checkbox-marked-circle-outline mdi-18px</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('mark_as_read') }}</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="item.read_at !== null" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-if="item.read_at !== null" v-on="on"
                                                   color="white" class="text--primary"
                                                   fab small icon @click.stop="notifyMark('unread', item)">
                                                <v-icon>mdi-checkbox-blank-circle-outline mdi-18px</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('mark_as_unread') }}</span>
                                    </v-tooltip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-divider v-if="(notifyItems.length - 1) !== index"></v-divider>
                    </template>
                </v-virtual-scroll>
            </v-card>
        </v-menu>
        <v-dialog v-if="dialogNotifyData" v-model="dialogNotify" max-width="500px" persistent eager :retain-focus="false">
            <v-card>
                <v-card-title class="subtitle-1 grey lighten-2" primary-title>
                    {{ dialogNotifyData.title }}
                </v-card-title>
                <v-card-text class="pt-4">
                    {{ dialogNotifyData.message }}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn v-show="$vuetify.breakpoint.xsOnly" class="mx-3" fab small dark color="error"
                           @click="notifyDelete('delete', dialogNotifyData)">
                        <v-icon>
                            mdi-trash-can
                        </v-icon>
                    </v-btn>
                    <v-btn v-show="!$vuetify.breakpoint.xsOnly" color="error" text
                           @click="notifyDelete('delete', dialogNotifyData)">
                        {{ $t('delete') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!dialogNotifyData.read_at" v-show="$vuetify.breakpoint.xsOnly" class="mx-3" fab small
                           dark color="success"
                           @click="notifyMark('read', dialogNotifyData)">
                        <v-icon>
                            mdi-checkbox-marked-circle
                        </v-icon>
                    </v-btn>
                    <v-btn v-if="!dialogNotifyData.read_at" v-show="!$vuetify.breakpoint.xsOnly" color="success" text
                           @click="notifyMark('read', dialogNotifyData)">
                        {{ $t('read') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogNotifyHidden">
                        {{ $t('close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app-bar>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
    name: "AppTopMenu",
    inject: ["forceRerender"],
    props: {
        mini: Boolean,
        drawer: Boolean,
        updateNotify: Boolean,
    },
    data() {
        return {
            isFullscreen: false,
            menu: false,
            menuBadge: false,
            closeOnClickMenu: true,
            dialogNotify: false,
            dialogNotifyData: [],
            items: [],
            messages: 0,
            notifyItems: [],
            notifications: [],
            transparent: 'rgba(255, 255, 255, 0)',
            settingsScrollbar: {
                suppressScrollX: true,
            },
            notifyType: 'unread',
            notifyReadCount: 0,
            notifyUnreadCount: 0
        }
    },
    computed: {
        ...mapGetters(['lang', 'showNotifyMessages', 'userPosition', 'userName', 'userAvatar']),
    },
    watch: {
        updateNotify(val) {
            if (val) this.getNotifications()
        },
        pushMessages(val) {
            if (val) {
                this.$toastr.success(`${val.title} ${val.message}`)
            }
        },
    },
    mounted() {
        this.menuNavigation()
        this.getNotifications()
    },
    methods: {
        ...mapActions(['setSettings', 'setLanguage', 'setUserName', 'setUserPosition', 'setUserAvatar']),
        dialogNotifyHidden() {
            this.dialogNotify = false
            this.dialogNotifyData = []
            this.closeOnClickMenu = true
        },
        navToggle() {
            this.$emit("nav-toggle")
        },
        navMiniToggle() {
            this.$emit("nav-mini-toggle")
        },
        showNotify(notify) {
            this.dialogNotifyData = notify
            this.closeOnClickMenu = true
            this.dialogNotify = true
        },
        notifyShow(type) {
            if (this.notifications?.length > 0) {
                this.$nextTick(() => {
                    this.notifyType = type
                    if (type === 'read') {
                        this.notifyItems = this.notifications.filter(notify => notify.read_at !== null).slice()
                    } else {
                        this.notifyItems = this.notifications.filter(notify => notify.read_at === null).slice()
                    }
                })
            }
        },
        async getNotifications(action) {
            var _this = this
            this.progress = 0
            this.loading = true
            let params = {}
            params.action = action ? action : 'all'
            await this.$http
                .get('admin/notification', {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.notifications = res.data

                    if (this.notifications?.length > 0) {
                        this.notifyReadCount = this.notifications.reduce((read, x) => (x.read_at !== null ? (read + 1) : read), 0)
                        this.notifyUnreadCount = this.notifications.reduce((unread, x) => (x.read_at === null ? (unread + 1) : unread), 0)

                        this.notifyShow(this.notifyType)
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_notifications'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async notifyMark(type, notify) {
            if (type) {
                var _this = this
                this.loading = true
                let formData = new FormData()
                formData.append('action', type)
                if (notify && notify.id) {
                    formData.append('notify', notify.id)
                }
                await this.$http
                    .put(`admin/notification/${type}`, formData)
                    .then(res => {
                        if (notify) {
                            const index = this.notifications.indexOf(notify)
                            if (type === 'read') {
                                this.notifications[index].read_at = this.$moment.now()
                            } else if (type === 'unread') {
                                this.notifications[index].read_at = null
                            }
                        } else {
                            if (type === 'readAll') {
                                this.notifications.forEach(function (item, i, arr) {
                                    item.read_at = _this.$moment.now()
                                })
                                this.notifyType = 'read'
                            } else if (type === 'unreadAll') {
                                this.notifications.forEach(function (item, i, arr) {
                                    item.read_at = null
                                })
                                this.notifyType = 'unread'
                            }
                        }

                        this.notifyReadCount = this.notifications.reduce((read, x) => (x.read_at !== null ? (read + 1) : read), 0)
                        this.notifyUnreadCount = this.notifications.reduce((unread, x) => (x.read_at === null ? (unread + 1) : unread), 0)

                        this.notifyShow(this.notifyType)

                        //this.getNotifications()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('notify_has_not_been_updated'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async notifyDelete(type, notify) {
            if (type) {
                this.loading = true
                let params = {}
                params.action = type
                if (notify && notify.id) {
                    params.notify = notify.id
                }
                await this.$http
                    .delete(`admin/notification/${type}`, {
                        params: params,
                    })
                    .then(res => {
                        if (notify) {
                            const index = this.notifications.indexOf(notify)
                            if (type === 'delete') {
                                this.notifications.splice(index, 1)
                            }
                        } else {
                            if (type === 'deleteAll') {
                                this.notifications = []
                            }
                        }

                        this.notifyShow(this.notifyType)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('notify_has_not_been_deleted'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
            this.dialogNotify = false
            this.dialogNotifyData = []
            this.closeOnClickMenu = true
        },
        async logout() {
            await this.$auth
                .logout({
                    makeRequest: true,
                    redirect: {
                        name: "login"
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('you_have_successfully_logged_out'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('error_occurred_while_exiting'))
                })
        },
        menuNavigation() {
            this.items = [
                {
                    title: this.$t('profile'),
                    icon: "mdi-account",
                    to: {
                        name: 'profile'
                    },
                    visible: this.$auth.check(),
                    action: this.forceRerender,
                    exact: false
                },
                {
                    title: this.$t('logout'),
                    icon: "mdi-logout-variant",
                    visible: this.$auth.check(),
                    action: this.logout
                }
            ]
        },
        toggleFullscreen: function (event) {
            var elem = document.documentElement
            if (
                document.fullscreenEnabled ||
                document.webkitFullscreenEnabled ||
                document.mozFullScreenEnabled ||
                document.msFullscreenEnabled
            ) {
                if (!this.isFullscreen) {
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen()
                        this.isFullscreen = true
                        return
                    } else if (elem.webkitRequestFullscreen) {
                        elem.webkitRequestFullscreen()
                        this.isFullscreen = true
                        return
                    } else if (elem.mozRequestFullScreen) {
                        elem.mozRequestFullScreen()
                        this.isFullscreen = true
                        return
                    } else if (elem.msRequestFullscreen) {
                        elem.msRequestFullscreen()
                        this.isFullscreen = true
                        return
                    }
                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen()
                        this.isFullscreen = false
                        return
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen()
                        this.isFullscreen = false
                        return
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen()
                        this.isFullscreen = false
                        return
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen()
                        this.isFullscreen = false
                        return
                    }
                }
            }
        },
    }
}
</script>
