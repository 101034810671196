import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter);

// eslint-disable-next-line no-sparse-arrays
let routes = [
    {
        path: '/',
        redirect: '/application',
    },
    {
        path: '/administrator',
        name: 'administrator',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "administrators" */ '../views/Administrators.vue'),
    },
    {
        path: '/administrator/create',
        name: 'administrator.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "administrator-form" */ '../views/AdministratorForm.vue'),
    },
    {
        path: '/administrator/:id/edit',
        name: 'administrator.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "administrator-form" */ '../views/AdministratorForm.vue'),
    },
    {
        path: '/client',
        name: 'client',
        meta: {layout: 'main', auth: {roles: ['administrator', "manager"]}},
        component: () => import(/* webpackChunkName: "clients" */ '../views/Clients.vue'),
    },
    {
        path: '/client/create',
        name: 'client.create',
        meta: {layout: 'main', auth: {roles: ['administrator', "manager"]}},
        component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue'),
    },
    {
        path: '/client/:id/edit',
        name: 'client.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', "manager"]}},
        component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue'),
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {layout: 'main', auth: true},
        component: () => import(/* webpackChunkName: "profile" */ '../views/AdministratorForm.vue'),
    },
    {
        path: '/country',
        name: 'country',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "countries" */ '../views/Countries.vue'),
    },
    {
        path: '/country/create',
        name: 'country.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "country-form" */ '../views/CountryForm.vue'),
    },
    {
        path: '/country/:id/edit',
        name: 'country.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "country-form" */ '../views/CountryForm.vue'),
    },
    {
        path: '/city',
        name: 'city',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "cities" */ '../views/Cities.vue'),
    },
    {
        path: '/city/create',
        name: 'city.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "city-form" */ '../views/CityForm.vue'),
    },
    {
        path: '/city/:id/edit',
        name: 'city.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "city-form" */ '../views/CityForm.vue'),
    },
    {
        path: '/station',
        name: 'station',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "station" */ '../views/Stations.vue'),
    },
    {
        path: '/station/create',
        name: 'station.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "station-form" */ '../views/StationForm.vue'),
    },
    {
        path: '/station/:id/edit',
        name: 'station.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "station-form" */ '../views/StationForm.vue'),
    },


    {
        path: '/condition',
        name: 'condition',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "condition" */ '../views/Conditions.vue'),
    },
    {
        path: '/condition/create',
        name: 'condition.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "condition-form" */ '../views/ConditionForm.vue'),
    },
    {
        path: '/condition/:id/edit',
        name: 'condition.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "condition-form" */ '../views/ConditionForm.vue'),
    },
    {
        path: '/tracking',
        name: 'tracking',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "Tracking" */ '../views/Tracking.vue'),
    },
    {
        path: '/tracking/create',
        name: 'tracking.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "tracking-form" */ '../views/TrackingForm.vue'),
    },
    {
        path: '/tracking/:id/edit',
        name: 'tracking.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "tracking-form" */ '../views/TrackingForm.vue'),
    },
    {
        path: '/tracking_container',
        name: 'tracking_container',
        meta: {layout: 'main', auth: { roles: ['administrator', "manager"] }},
        component: () => import(/* webpackChunkName: "tracking_containers" */'../views/TrackingContainers.vue'),
    },
    {
        path: '/tracking_container/:tracking_id',
        name: 'tracking_container_form',
        meta: {layout: 'main', auth: { roles: ['administrator', "manager"] }},
        component: () => import(/* webpackChunkName: "tracking_container_form" */'../views/TrackingContainerForm.vue'),
    },
    {
        path: '/container',
        name: 'container',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "containers" */ '../views/Containers.vue'),
    },
    {
        path: '/container/create',
        name: 'container.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "container-form" */ '../views/ContainerForm.vue'),
    },
    {
        path: '/container/:id/edit',
        name: 'container.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "container-form" */ '../views/ContainerForm.vue'),
    },
    {
        path: '/wagon',
        name: 'wagon',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "countries" */ '../views/Wagons.vue'),
    },
    {
        path: '/wagon/create',
        name: 'wagon.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "wagon-form" */ '../views/WagonForm.vue'),
    },
    {
        path: '/wagon/:id/edit',
        name: 'wagon.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "wagon-form" */ '../views/WagonForm.vue'),
    },
    {
        path: '/container_type',
        name: 'container_type',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "container_types" */ '../views/ContainerTypes.vue'),
    },
    {
        path: '/container_type/create',
        name: 'container_type.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "container_type-form" */ '../views/ContainerTypeForm.vue'),
    },
    {
        path: '/container_type/:id/edit',
        name: 'container_type.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "container_type-form" */ '../views/ContainerTypeForm.vue'),
    },
    {
        path: '/application/update',
        name: 'update_application',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "update_application" */ '../views/ApplicationUpdateForm.vue'),
    },
    {
        path: '/application/client/update',
        name: 'update_application_client',
        meta: {layout: 'main', auth: {roles: ['administrator', "manager"]}},
        component: () => import(/* webpackChunkName: "update_application" */ '../views/ApplicationClientUpdateForm.vue'),
    },

    {
        path: '/application',
        name: 'application',
        meta: {layout: 'main', auth: {roles: ['administrator', "manager"]}},
        component: () => import(/* webpackChunkName: "applications" */ '../views/Applications.vue'),
    },
    {
        path: '/status',
        name: 'status',
        meta: {layout: 'main', auth: { roles: ['administrator'] }},
        component: () => import('../views/Statuses.vue'),
    },
    {
        path: '/status/create',
        name: 'status.create',
        meta: {layout: 'main', auth: { roles: ['administrator'] }},
        component: () => import('../views/StatusForm.vue'),
    },
    {
        path: '/status/:id/edit',
        name: 'status.edit',
        meta: {layout: 'main', auth: { roles: ['administrator'] }},
        component: () => import('../views/StatusForm.vue'),
    },
    {
        path: '/status_railway/:status_id',
        name: 'status_railway',
        meta: {layout: 'main', auth: { roles: ['administrator'] }},
        component: () => import('../views/StatusRailways.vue'),
    },
    {
        path: '/status_railway/:status_id/create',
        name: 'status_railway.create',
        meta: {layout: 'main', auth: { roles: ['administrator'] }},
        component: () => import('../views/StatusRailwaysForm.vue'),
    },
    {
        path: '/status_railway/:status_id/edit/:id',
        name: 'status_railway.edit',
        meta: {layout: 'main', auth: { roles: ['administrator'] }},
        component: () => import('../views/StatusRailwaysForm.vue'),
    },
    {
        path: '/integration_data',
        name: 'integration_data',
        meta: {layout: 'main', auth: { roles: ['administrator'] }},
        component: () => import(/* webpackChunkName: "integration-data" */ '../views/IntegrationData.vue'),
    },
    {
        path: '/integration_errors',
        name: 'integration_errors',
        meta: {layout: 'main', auth: { roles: ['administrator'] }},
        component: () => import(/* webpackChunkName: "integration-errors" */ '../views/IntegrationErrors.vue'),
    },
    {
        path: '/company',
        name: 'company',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "companies" */ '../views/Companies.vue'),
    },
    {
        path: '/company/create',
        name: 'company.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "company-form" */ '../views/CompanyForm.vue'),
    },
    {
        path: '/company/:id/edit',
        name: 'company.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "company-form" */ '../views/CompanyForm.vue'),
    },
    {
        path: '/auth/login',
        name: 'login',
        meta: { layout: 'single-page', auth: false },
        component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue'),
    },
    {
        path: '/auth/register',
        name: 'register',
        meta: {layout: 'single-page', auth: false},
        component: () => import(/* webpackChunkName: "register" */ '../views/Auth/Login.vue'),
    },
    {
        path: '/auth/forgot',
        name: 'forgot',
        meta: {layout: 'single-page', auth: false},
        component: () => import(/* webpackChunkName: "forgot" */ '../views/Auth/Forgot.vue'),
    },
    {
        path: '/auth/forgot/check',
        name: 'reset_password',
        meta: {layout: 'single-page', auth: false},
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/Auth/ResetPassword.vue'),
    },
    {
        path: '/404',
        name: 'error-404',
        meta: {layout: 'main', auth: true},
        component: () => import(/* webpackChunkName: "not-found" */ '../views/Errors/NotFound.vue'),
    },
    {
        path: '/403',
        name: 'error-403',
        meta: {layout: 'main', auth: true},
        component: () => import(/* webpackChunkName: "forbidden" */ '../views/Errors/Forbidden.vue'),
    },
    {
        path: '/block',
        name: 'block',
        meta: {layout: 'single-page', auth: undefined},
        component: () => import(/* webpackChunkName: "blocked" */ '../views/Errors/Blocked.vue'),
    },
];


const router = new VueRouter({
    scrollBehavior() {
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    hashbang: false,
    linkActiveClass: 'active',
    transitionOnLoad: true,
    mode: 'history',
    base: __dirname,
    routes,
});

export default router;
